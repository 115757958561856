






































































































































































































































































































































































































































































































































































































































































































































































































.main_topics_wrapper_container {
  .add_topics_dialog {
    .el-dialog {
      @media (min-width: 320px) and (max-width: 767px) {
        width: 90% !important;
      }
    }
  }
}
